import React from 'react';
import { Tooltip } from 'react-leaflet';
import { Link } from 'react-router-dom';

import Button from '../Buttons';

import {
  Identifier,
  TooltipContainer,
  TooltipTriangle,
  StyledCircleMarker,
  StyledPopup
} from './styles';

function Tree({ center, id = 0, qtd = 0, props }) {
  return (
    <StyledCircleMarker
      radius={12}
      center={center}
      fillOpacity={0}
      stroke={false}
      eventHandlers={{
        click: (e) => {
          e.target.openPopup();
        }
      }}
    >
      <StyledPopup>
        <Link
          to={`/Mapas/MIP/Resumo?id=${id}&subarea=${props?.subarea}&data=${props?.data}&praga=${props?.praga}&metodo=${props?.metodo}`}
          target="blank"
        >
          <Button>Ver Resumo</Button>
        </Link>
      </StyledPopup>

      {props?.status === 'Pós Pulverização' ? (
        <Tooltip permanent direction="center" interactive>
          <TooltipContainer>
            <TooltipTriangle qtd={qtd} id={id}>
              <span>{id}</span>
            </TooltipTriangle>
          </TooltipContainer>
        </Tooltip>
      ) : (
        <Identifier permanent direction="center" qtd={qtd} interactive>
          {id}
        </Identifier>
      )}
    </StyledCircleMarker>
  );
}

export default Tree;